.BusinessSuppliers_head{
    background-color: rgb(184, 33, 33);
    padding: 40px 0;
}
.BusinessSuppliers_head h4{
    color: white;
    font-weight: bold;
    background: linear-gradient(to right, rgba(112, 20, 20, 1), rgba(112, 20, 20, 0));
    padding: 15px 50px;
    margin-bottom: 0;

}

.Suppliers_Restaurant {
    padding: 10px 5px;
}

.Suppliers_Restaurant .Suppliers_detail{
    display: flex;
    padding: 20px;
}
.Suppliers_Restaurant .Suppliers_detail div{
    text-align: justify;
}

.SuppliersRestaurant_imgdiv{
    padding: 0 30px;
}
.SuppliersRestaurant_imgdiv img {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}
.Suppliers_Restaurant .Suppliers_detail div a{
    text-decoration: none;
    color: rgb(34, 30, 30);

}
.Suppliers_Restaurant .Suppliers_detail div h4 {
    margin: 0px 0 10px 0;
}

.Suppliers_Restaurant .Suppliers_detail div .supp_info {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.Supplier_btndiv{
    margin: 20px 10px;
}

.Supplier_btndiv h2{
    font-weight: bold;
    font-size: 24px;
}
.Supplier_btndiv div button{
     border: 1px solid lightgray;
     background-color: white;
     font-weight: 500;
     color: rgb(34, 30, 30);
     text-transform: capitalize;
     margin:10px 20px 10px 0;
}
.Supplier_btndiv div button:hover{
    background-color: rgb(184, 33, 33);
    color: white;
}

@media screen and (max-width:767px) {
    .Suppliers_Restaurant {
        padding: 10px 10px;
        width: 100%;
    }

    .Suppliers_Restaurant h1 {
        font-size: 18px;
    }

    .SuppliersRestaurant_imgdiv img {
        height: 250px;
        width: 300px !important;
        margin-left: 10%;
    }

}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .Suppliers_Restaurant {
        padding: 10px 5px;
    }

    .SuppliersRestaurant_imgdiv img {
        height: 250px;
        width: 200px;
        object-fit: contain;
        border-radius: 4px;
    }
}