.main-div {
    padding: 40px 0;
    background-color: #000000;
    color: white;
}

.maindiv-box {
    display: flex;
    justify-content: space-between;
    height: 115vh;
    margin:0 20px;
}

.alltabs {
    /* width: 400px; */
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-top: 20px;
}

.mange-tab {
    /* width: 380px; */
    padding: 3px 2px 3px 10px;
    background-color: black;
    text-decoration: none;
    border: none;
    margin: 10px;
    font-size: 16px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    /* border-left: 2px solid grey; */
    text-align: start;
    display: flex;
    align-items: center;
}

.mange-tab a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
}

/* .mange-tab a:hover {
    color: gold;
} */

/* .mange-tab:hover {
    border-left: 4px solid gold;
    color: gold;
    filter: brightness(170%);

} */

.mange-tab .running-text {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    position: relative;
    width: 120px;
    margin:0 5px;
}

.mange-tab .running-text::before {
    content:  attr(data-text) ;
    display: inline;
    position: absolute;
    animation: runText 8s linear infinite;
}

@keyframes runText {
    0% {
        transform: translateX(70%);
    }

    50% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(70%);
    }
}



.tabpanels {
    /* width: 900px; */
    height: 115vh;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 10px 0 !important;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(79, 77, 77);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(77, 74, 74);
}

.about-marx-entmnt {
    text-align: center;
    margin: 40px;
}

.about-marx-entmnt h1 {
    font-size: 22px;
    font-weight: bold;
}

.about-marx-entmnt p {
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 30px;
    text-align: justify;
}

.board-director {
    text-align: center;
    margin: 40px;
}

.board-director h1 {
    font-size: 22px;
    font-weight: bold;
    margin: 20px 0;
}

.each-director div {
    width: 400px;
    text-align: center !important;
    margin: auto;
}

.each-director div img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px 0;
}

.founder-chairmsg {
    text-align: center;
    margin: 40px;
}

.founder-chairmsg h1 {
    font-size: 22px;
    font-weight: bold;
}

.foundermsg {
    width: 400px;
    text-align: center !important;
    margin: auto;
}

.foundermsg img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px 0 0 -10px;
    text-align: center !important;

}

.imgg-sldddiv {
    padding: 30px 30px 50px 30px;
    background-color: black;
}
.imgg-sldddiv h1{
    font-size: 26px;
    text-align: start;
}

.imgg-sldddiv .divvb {
    padding: 5px;
}

.sliderr-box {
    margin: 10px 10px;
    overflow: hidden;
}

.img-slidebox {
    width: 100% !important;
    text-align: center;
    border-radius: 6px;
    margin-bottom: 10px;
    position: relative;
    animation: slideTrain 8s linear infinite;
}

.img-slidebox img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

@keyframes slideTrain {
    0% {
        transform: translateX(100%);
        /* Initial position, starting off-screen to the right */
    }

    100% {
        transform: translateX(-100%);
    }
}

.latestnews-sldddiv {
    background-color: rgb(0, 0, 0);
    padding: 30px 10px;
}
.latestnews-sldddiv h1{
    font-size: 26px;
    text-align: start;
}

.slidrbox {
    margin: 10px 8px;
}

.divlatest {
    padding: 10px 10px 0 10px;
    margin: 0 10px;
}

.div-slide {
    width: 380px !important;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 0px 20px 0;
    overflow: hidden;
}

.div-slide img {
    width: 380px;
    height: 220px;
    border-radius: 6px 6px 0 0;
    object-fit: contain;
}

.div-slide p {
    color: rgb(0, 0, 0);
    margin: 10px 20px;
    font-size: 12px;
}

.div-slide h6 {
    color: rgb(0, 0, 0);
    margin: 0 20px;
}

@media screen and (max-width:767px) {
    .topdivbar {
        display: block;
    }

    .main-div {
        padding: 20px 0;
    }

    .maindiv-box {
        display: block;
        height: auto;
    }

    .tabpanels {
        width: 96%;
        margin-left: 2px;
        margin-top: 10px;
        overflow-y: unset;
        border: none;
        height: unset;
    }

    .about-marx-entmnt p {
        text-align: justify;
        letter-spacing: 0px;

    }

    .about-marx-entmnt {
        margin: 40px 20px;
    }

    .alltabs {
        width: 95%;
        margin-left: 10px;
        padding: 20px 0;
    }

    .mange-tab {
        width: 90%;
        padding: 3px 10px;
        margin: 10px;
        border-left: unset;

    }

    .mange-tab:hover {
        border-left: unset;

    }
    .mange-tab a {
        display: block;
        align-items: center;
    }
    .board-director {
        margin: 10px;
    }

    .each-director div {
        width: 100%;
    }

    .each-director div p {
        padding: 0 10px;
    }

    .founder-chairmsg {
        margin: 10px;
    }

    .founder-chairmsg p {
        padding: 0 10px;
    }

    .foundermsg {
        width: 100%;
    }

    .imgg-sldddiv {
        padding: 1px;
    }

    .sliderr-box {
        margin: 5px 2px;
    }

    .img-slidebox img {
        height: 200px !important;
        width: 340px;
    }

    .latestnews-sldddiv {
        padding: 30px 2px;
    }
    .latestnews-sldddiv h1{
        font-size: 18px;
    }

    .divlatest {
        padding: 1px;
    }

}

@media screen and (min-width: 768px) and (max-width: 992px) { 
    .main-div {
        padding: 20px 0;
    }

    .maindiv-box {
        display: block;
        height: auto;
    }

    .tabpanels {
        width: 100%;
        margin-left: 2px;
        margin-top: 10px;
        overflow-y: unset;
        border: none;
        height: unset;
    }

    .about-marx-entmnt p {
        text-align: justify;
        letter-spacing: 0px;

    }

    .about-marx-entmnt {
        margin: 40px 20px;
    }

    .alltabs {
        width: 97%;
        margin-left: 10px;
        padding: 20px 0;
    }

    .mange-tab {
        width: 90%;
        padding: 3px 10px;
        margin: 10px;
        border-left: unset;

    }

    .mange-tab:hover {
        border-left: unset;

    }
    .mange-tab a {
        display: block;
    }

    .board-director {
        margin: 10px;
    }

    .each-director div {
        width: 100%;
    }

    .each-director div p {
        padding: 0 10px;
    }

    .founder-chairmsg {
        margin: 10px;
    }

    .founder-chairmsg p {
        padding: 0 10px;
    }

    .foundermsg {
        width: 100%;
    }

    .imgg-sldddiv {
        padding: 1px;
    }

    .sliderr-box {
        margin: 5px 2px;
    }

    .img-slidebox img {
        height: 200px !important;
        width: 100%;
    }

    .latestnews-sldddiv {
        padding: 30px 2px;
    }

    .divlatest {
        padding: 1px;
    }
    
}