
.FoodServicediv {
    width: 100%;
    text-align: left;
    position: relative;
    margin: 40px 0;
}
.FoodServicediv::after {
    content: '';
    position: absolute;
    left: 284px;
    right: 0;
    top: 53%;
    height: 2px;
    background: #000;
    z-index: 0;
}

.FoodServicediv h1 {
    position: relative;
    padding: 0px 20px 0px 0px;
    background: #ffffff;
    display: inline-block;
    z-index: 1;
    color: #3B3B3B;
    font-size: 44px !important;
    font-weight: 700;
}

.FoodService_Restaurant .FoodService_detail div a{
    text-decoration: none;
    color: rgb(34, 30, 30);

}
.FoodService_Restaurant .FoodService_detail div h4 {
    margin: 0px 0 10px 0;
}
.FoodService_Restaurant .FoodService_detail div .textdanger {
    color:rgb(184, 33, 33);
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;

}
.FoodService_Restaurant .FoodService_detail div .food_info {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0 0;
}


.FoodService_Restaurant .FoodService_detail{
    display: flex;
    padding: 20px;
}
.FoodService_Restaurant .FoodService_detail div{
    text-align: justify;
}

.FoodServiceRestaurant_imgdiv{
    padding: 0 30px;
}
.FoodServiceRestaurant_imgdiv img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
}

.FoodServiceRestaurant_imgdiv .imgdate p{
    font-size: 14px;
    color: gray;
    text-transform: capitalize;
    margin: 8px 0 0 4px;
}

.FoodService_right{
    border: 1px solid #F7F7F7;
    background-color: #F7F7F7;
    padding: 20px;
    border-radius: 4px;
    margin-top: 10px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}
.FoodService_right h4{
    font-weight: 400;
    margin: 10px 0;
}
.FoodService_right p{
    font-size: 14px;
    margin: 20px 0;

}
.FoodService_right .Limitedoffer{
    background-color: white;
    padding: 20px;
    margin-bottom: 30px;
    text-align: center;
}

.FoodService_right .Limitedoffer .textsuccess{
    color: green;
    font-size: 18px;
    margin-bottom: 5px;
}
.FoodService_right .Limitedoffer h1{
    font-weight: bold;
    color:rgb(210, 39, 39);
    margin: 30px 0;
}
.FoodService_right .Limitedoffer h1 span{
    font-size: 14px;

}

.FoodService_right .Limitedoffer h3{
    text-decoration: line-through;
    color: gray;
}
.FoodService_right .Limitedoffer .btnn1{
    background-color: #e7f1e9;
    color: #3B3B3B;
    text-transform:lowercase;
    margin: 20px 0 40px 0;
    padding: 10px 30px;

}
.FoodService_right .Limitedoffer .ulstories{
    text-align: left;
    margin: 10px 20px;
    font-size: 17px;
}
.FoodService_right .Limitedoffer .btnn2{
    background-color: rgb(210, 39, 39);
    text-transform:lowercase;
    color: white;
    font-size: 20px;
    margin: 20px 0 10px 0;
    padding: 10px;

}

.LoadMoreDiv{
     display: flex;
     justify-content: center;
     align-items: center;
}
.LoadMoreDiv button{
    background-color: #1779ba;
    text-transform:capitalize;
    color: white;
    font-size: 18px;
    margin: 30px 0;
    padding: 10px 50px;
}

.LoadMoreDiv button:hover{
    background-color: #1779ba;
    color: white;
}

.FeaturedCollections{
    margin: 40px 0;
}


.FoodService_area {
    width: 100%;
    margin: 20px 10px;
    border-radius: 6px;
    text-align: center;
}
.FoodService_area div img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 1%;
    margin: 20px 0;

}

.FoodService_alldetail {
    display: flex;
    flex-wrap: wrap;
}
.linktag_food {
    text-decoration: none;
}