.newfooter {
    background-color: #201d1d;
    padding: 20px 0px 40px 0px;
    margin-top: 0px;
    position: relative;
}

img.footerlogo {
    width: 95px;
    border-radius: 6px;
    margin-top: 40px;
}
.footerlnk-head {
    font-size: 23px;
    color: #ffffff;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 40px;
}

.footer-sub-head {
    display: block;
    color: #ffffff;
    margin: 18px 0px;
    font-size: 20px;
}

.redlnk {
    background-color: #DB2127;
    color: #ffffff;
    text-decoration: none;
    padding: 9px 30px;
    border-radius: 10px;
    display: inline-block;
    margin: 14px 0;
}

.soc_ul_footer,.soc-ul-footer {
    list-style: none;
    display: flex;
    padding-left: 0px;
    margin-top: 11px;
}

.soc_ul_footer li, .soc-ul-footer li {
    margin-right: 15px;
}

.menu {
    list-style: none;
    padding-left: 0px;
    margin: 10px 0px;
}
.menu li {
    margin-bottom: 10px;
}

.menu li a {
    text-decoration: none;
    color: #e8e8e8;
    font-size: 16px;
    line-height: 24px;
}

.mag {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 8px 16px #00000029;
    border-radius: 4px;
    margin-top: 30px;
}

.mag-head {
    color: #48494A;
    font-size: 21px;
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.mag-inner {
    list-style: none;
    padding-left: 0px;
    display: flex;
    justify-content: space-between;
}

.mag-inner li:first-child {
    width: 238px;
    margin-right: 13px;
}
.mag-inner img {
    height: 154px;
    object-fit: cover;
    width: 112px;
    height: 154px;
    object-fit: contain;
    width: 100%;
}

.mag1 {
    color: #48494A;
    font-size: 17px;
    display: block;
    margin-bottom: 10px;
    line-height: 23px;
    margin-top: 17px;
    font-weight: 600;
}

.subscribe {
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    background-color: #DB2127;
    border-radius: 4px;
    padding: 10px 24px;
    box-shadow: 0px 8px 16px #00000029;
    margin-top: 18px;
}

.affliates {
    margin-top: 40px;
}

.affliates_lnk,.affliates-lnk {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0px;
    margin-top: 17px;
}
.affliates_lnk li,.affliates-lnk li {
    background-color: #ffffff;
    margin: 0px 20px 10px 0px;
    width: 100px;
}


.copyright {
    background-color: #2d2a2a;
    padding: 28px;
}

.copy p {
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    margin-bottom: 0px;
    font-family: Arial;
}