.topHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    backdrop-filter: blur(15px);
    box-shadow: 0 0 2px white;
    padding: 0.4rem;
    position: sticky !important;
    top: 0 !important;
    z-index: 9999;
    color: black;
  
  }
  
  .heading-div-mobile {
    display: none;
  }
  
  .heading-div-mobile button .fs-1textwhite {
    color: black;
    font-size: 38px;
  }
  
  .topHeader .logo {
    width: 3.5rem;
    margin-left: 34px;
    border-radius: 0.8rem;
  }
  .topHeader a{
    text-decoration: none;
    
  }
  .topHeader .compnyName{
    font-weight: bold;
    font-size: 24px;
    margin-left: 10px;
    color: #4db5ff;
  }
  
  .topHeader .liauto {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
  }
  
  .topHeader .liauto li {
    margin: 0 .6rem;
  }
  
  .topHeader .liauto li a {
    padding: 0.5rem 0.6rem;
    border-radius: 6px;
    text-decoration: none;
    color: #4db5ff;
  }

  
  .topHeader .liauto li a:hover {
    background: rgba(142, 186, 241, 0.3);
    border-radius: 6px;
  }
  
  .topHeader .liauto li a.active {
    background: #4db5ff;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
  
  }
  
  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
  
    to {
      transform: translateY(0rem);
    }
  }
  
  @media only screen and (max-width: 767px) {
    .topHeader {
      z-index: 2;
      width: 100%;
    
    }
    .rightUL {
      display: none;
    }
  
    .heading-div-mobile {
      display: block;
      width: 230px;
    }
  
    .topHeader .logo {
      width: 3.2rem;
      margin:0 10px;
      border-radius: 0.5rem;
    }
    .topHeader .compnyName{
      display: none;
    }
  }