.contact_topDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact_topDiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    filter: brightness(80%);

}

.formdiv div input {
    height: 50px;
}

.office_location {
    width: 400px;
    background-color: #eefbef;
    padding: 20px;
    border-radius: 4px;
}
.office_location h4{
    color: rgb(66, 64, 64);
}


.contactdiv_location {
    display: flex;
    margin: 20px 0;

}

.contactdiv_location .icondiv {
    margin-right: 15px;
}

.contactdiv_location .icondiv p {
    background-color: #4db5ff;
    color: white;
    border-radius: 50%;
}

.contactdiv_location h6 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}

.contactdiv_location p {
    margin: 0;
    font-size: 18px;

}
@media only screen and (max-width: 767px) {
    .contact_topDiv img {
        width: 100%;
        height: 250px;
    }
}