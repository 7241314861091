.EventSessions{
    border: 1px solid #F7F7F7;
    padding: 20px;
    border-radius: 4px;
    margin-top: 10px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}
.EventSessions h4{
    font-weight: bold;
}
.EventSessions p{
    font-size: 18px;
}
.EventSessions a{
    color: rgb(184, 33, 33);
    font-weight: bold;
    text-decoration: none;
    font-size: 17px;
}
.EventSessions a:hover{
    text-decoration: underline;
}
.imgdivv {
    display: flex;
    justify-content: center;
}
.imgdivv img{
    width: 90%;
    margin: 20px 0;
    border-radius: 4px;
}

.KnowService{
    border: 1px solid lightgray;
    padding: 20px;
    border-radius: 4px;
    margin-top: 10px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}

.KnowService h6{
    font-weight: bold;
    font-size: 24px;
}
.KnowService div:not(:last-child){
    border-bottom: 1px solid lightgray;
    padding: 20px 0;
}
.KnowService div{
    padding: 20px 0;
}

.KnowService div p{
    font-size: 16px;
    margin-bottom:8px;

}
.KnowService div p a{
    color: gray;
    text-decoration: none;
}

.KnowService div h5{
    font-size: 18px;
    
}

.KnowService div h5 a{
    color: rgb(41, 39, 39);
    text-decoration: none;
}
.butnsdiv{
    margin: 20px 0;
}
.butnsdiv button{
    background-color: rgb(184, 33, 33);
    color: white;
    margin: 10px;
    text-transform: capitalize;
}

.butnsdiv button:hover{
    background-color: rgb(92, 24, 24);
    color: white;
}
.advertise_div{
    display: flex;
    justify-content: center;
}
.advertise_div img{
    width: 90%;
    height: 140px;
    object-fit: contain;
    border-radius: 4px;
    margin: 20px 0;
}
.newItemsAdd{
    margin: 20px 0;
}
.newItemsAdd h5{
    font-weight: bold;
    margin: 20px 0;
    font-size: 24px;
}
.newItemsAdd h5 a{
    text-decoration: none;
    color: rgb(65, 58, 58);

}
.newItemsAdd .dflex_Div {
    display: flex;
    justify-content: space-between;

}
.newItemsAdd .dflex_Div div img{
    width: 250px;
    object-fit: cover;
    height: 200px;
    border-radius: 4px;
    margin-top: 10px;
}
.newItemsAdd .dflex_Div .content_div{
    margin-left: 20px;
}
.newItemsAdd .dflex_Div .content_div h6{
    font-size: 19px;
    border-bottom: 1px solid lightgray;
    padding: 10px 0;
}

.newItemsAdd .dflex_Div .content_div h6 a{
    text-decoration: none;
    color: rgb(65, 58, 58);
}
.Get_daily_news{
     background-color:  rgb(184, 33, 33);
     padding: 60px 0;
}
.Get_daily_news .maindiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
}
.newshead h1{
     color: white;
     font-size: 24px;
     margin: 0 10%;
}
.inputdivfiled input{
    width: 400px !important;
}
.LatestVideodiv{
     background-color: rgb(41, 39, 39);
     color: white;
}
.LatestVideodiv .andviewmore{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
}
.LatestVideodiv .andviewmore h5{
    margin: 0;
    font-size: 28px;
    font-weight: bold;
}
.LatestVideodiv .andviewmore button{
    border: 1px solid white;
    color: white;
    padding: 8px 20px;
    text-transform: capitalize;

}
.LatestVideodiv .andviewmore button:hover{
    background-color: rgb(184, 33, 33);
    border: 1px solid rgb(184, 33, 33);
    color: white;
}

.advertise_div2{
    display: flex;
    justify-content: center;
}

.advertise_div2 img{
    width: 90%;
    height: 200px;
    object-fit: contain;
    border-radius: 4px;
    margin: 20px 0;
}